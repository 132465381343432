import React from 'react'
import Image from '~/components/atoms/Image'
import IconArrow from '~/components/atoms/IconArrow'
import { Link } from 'gatsby'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function HeroStory({
  title='',
  image,
  stat
}) {
  return (
    <section className="mb-20 md:mb-36 py-40 pb-32 bg-sand">
      <div className={`container lg:px-10 !py-0 mb-10 lg:-mb-4`}>
        <Link to="/partners/" className="font-bold flex items-center ml-2 hover:opacity-50 duration-200 transition-opacity ease-in-out"><IconArrow className="transform rotate-90 mr-4" /><div className="text-h6">Back to partner stories</div></Link>
      </div>
      <div className="container">
        <div className="md:grid grid-cols-12 items-center ">
          <FadeInWhenVisible className="col-span-12 lg:col-span-5 mb-12 lg:mb-0 lg:pl-14">
            <h1 className="text-h2" dangerouslySetInnerHTML={{__html: title }}/>
          </FadeInWhenVisible>
          <FadeInWhenVisible className={`relative col-span-12 lg:col-span-6 lg:col-start-7`}>
            <div className="w-full lg:w-4/5 ml-auto block">
              <div className="aspect-w-1 aspect-h-1">
                <Image loading={'eager'} data={image} className={`!absolute w-full h-full`} />
              </div>
            </div>
            {stat && stat.heading && (
              <div className="bg-white py-16 px-8 inline-block max-w-[15rem] text-center transform absolute -translate-y-3/4">
                <div className="text-h1">{stat?.heading}</div>
                <div className="text-h6">{stat?.content}</div>
              </div>
            )}
          </FadeInWhenVisible>
        </div>

      </div>
    </section>
  )
}
