import { graphql } from 'gatsby';
import React from 'react';
import SEO from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import SocialSharing from '~/components/molecules/SocialSharing';
import Cta from '~/components/organisms/Cta';
import FeaturedStories from '~/components/organisms/FeaturedStories';
import HeroStory from '~/components/organisms/HeroStory';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function PartnersStoryPage({ data }) {
  const { title, content, featuredImage, partnerStoryItem } = data.wpPartnerStory;
  const acf = partnerStoryItem?.partnerStoryItem;
  const relatedStories = acf?.relatedPartnerStories?.map(p => p.post);

  return (
    <Layout showLogin>
      <SEO post={data.wpPartnerStory} />
      <HeroStory title={title} image={featuredImage?.node} stat={acf?.stat} />
      <section className="my-20 md:my-36">
        <FadeInWhenVisible className="container">
          <div
            className="prose max-w-xl mx-auto border-b border-b-navy border-opacity-10 pb-16"
            dangerouslySetInnerHTML={{__html: content }}
          />
          <SocialSharing className="mt-16" />
        </FadeInWhenVisible>
      </section>

      <Cta {...acf} />

      <FeaturedStories className="!my-20 md:!my-32 !py-0 md:!py-0 !bg-transparent" heading="More partner stories" posts={relatedStories} />
    </Layout>
  )
}


export const pageQuery = graphql`
  query PartnersStoryQuery($id: String!) {
    wpPartnerStory(id: { eq: $id }) {
      title
      content
      ...PartnerSEO
      featuredImage {
        node {
          ... Image
        }
      }
      partnerStoryItem {
        partnerStoryItem {
          stat {
            heading
            content
          }
          relatedPartnerStories {
            post {
              ... on WpPartnerStory {
                ...PartnerPreview
              }
            }
          }
          cta {
            illustrationSize
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText

              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
            illustration {
              ... Image
            }
            fieldGroupName
          }
        }
      }
    }

  }
`;
